<template>
  <div>
    <v-card-title>
      <span class="mx-2"> Medicamentos </span>
      <v-chip v-if="items.length" color="teal lighten-1" dark>
        {{ items.length }}
      </v-chip>
      <v-skeleton-loader v-else type="chip" />
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="item in propeties" :key="item.key" class="text-center">
              {{ item.title }}
            </th>
            <th>RP. anulado</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items.length">
            <tr
              v-for="row in items"
              :key="row.id"
              :class="{ strikeout: row.nulled == 1, 'text-center': true }"
            >
              <td v-for="item in propeties" :key="item.key">
                {{ row[item.key] }}
              </td>
              <td>
                <v-btn
                  :color="row.nulled ? 'red' : 'teal'"
                  depressed
                  :disabled="pharmacyId != 0"
                  icon
                  @click="row.nulled = !row.nulled"
                >
                  <v-icon size="40">
                    {{ row.nulled ? "mdi-close-box" : "mdi-checkbox-marked" }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              Sin resultados
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "RecipeDetailsTable",
  props: {
    items: {
      type: Array,
      required: false,
    },
    pharmacyId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    propeties: [
      {
        key: "rp",
        title: "RP",
      },
      {
        key: "plan",
        title: "Plan",
      },
      {
        key: "quantity",
        title: "Cantidad",
      },
      {
        key: "orderedMed",
        title: "Medicamento Pedido",
      },
      {
        key: "deliveredMed",
        title: "Medicamento Entrgado",
      },
      {
        key: "orderDate",
        title: "F. Pedido",
      },
      {
        key: "deliveryDate",
        title: "F. Entrega",
      },
    ],
  }),
};
</script>

<style>
table {
  border-collapse: collapse;
}

td {
  position: relative;
  padding: 5px 10px;
}

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #111;
  width: 100%;
}
</style>
