<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      v-on:click:outside="$emit('closedialog')"
      max-width='80%'
      min-width='60%'
    >
      <v-card class="rounded20 pa-10" v-if="recipeReturn">
        <v-card-title class="justify-center">
          Devolución de renglones de la receta {{ recipeReturn.id }}
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col>
              <v-card flat>
                <v-list dense v-if="recipeReturn.affiliate">
                  <v-list-item
                    v-for="propety in affiliatePropeties"
                    :key="propety.key"
                  >
                    <v-list-item-avatar>
                      <v-icon size="20">
                        {{ propety.icon }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ propety.label }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ recipeReturn.affiliate[propety.key] }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-chip v-else color="red" dark> Sin afiliado </v-chip>
              </v-card>
            </v-col>
            <v-col>
              <v-card flat>
                <v-list dense>
                  <v-list-item
                    v-for="propety in doctorPropeties"
                    :key="propety.key"
                  >
                    <v-list-item-avatar>
                      <v-icon size="20">
                        {{ propety.icon }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ propety.label }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ recipeReturn.doctor[propety.key] }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <MedsReturnTable :meds="recipeReturn.meds" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="$emit('markreturn', recipeReturn)"
            color="teal"
            rounded outlined
          >
            <v-icon class="mr-1"> mdi-printer </v-icon>
            Imprimir
          </v-btn>
          <v-btn
            @click="$emit('closedialog')"
            text
            v-text="'Cerrar'"
          />
        </v-card-actions>
      </v-card>
      <v-alert color="red" v-else> Error interno. </v-alert>
    </v-dialog>
    <v-snackbar
      :timeout="5000"
      rounded
      bottom
      v-model="error.status"
      :color="error.color"
    >
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon rounded v-bind="attrs" @click="error.status = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import MedsReturnTable from "./MedsReturnTableComponent";

export default {
  name: "ReturnDialog",
  components: {
    MedsReturnTable,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    recipeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    affiliatePropeties: [
      {
        key: "name",
        label: "Afiliado",
        icon: "mdi-account",
      },
      {
        key: "phone",
        label: "Teléfono",
        icon: "mdi-phone",
      },
    ],
    doctorPropeties: [
      {
        key: "name",
        label: "Doctor",
        icon: "mdi-account",
      },
      {
        key: "phone",
        label: "Teléfono",
        icon: "mdi-phone",
      },
    ],
    message: "",
    error: {
      status: false,
      message: "",
      color: "",
    },
  }),

  mounted() {
    this.fetchReturnRecipe();
  },

  computed: {
    ...mapGetters("recipes", ["recipeReturn", "returnLoading"]),
  },

  methods: {
    ...mapActions("recipes", ["getRecipeReturn"]),

    async fetchReturnRecipe() {
      const response = await this.getRecipeReturn(this.recipeId);
      if (response.status !== 200 || response.data.message) {
        if (response.status === 500) {
          this.error = { status: true, message: "Error interno" };
        } else {
          this.error = {
            status: true,
            message: response.data.message,
          };
        }
      } else {
        this.dialog = true;
      }
    },
  },
};
</script>
