<template>
  <div>
    <section v-if="!loading">
      <v-sheet color="white" class="pa-10 rounded20">
        <h2 class="overline">Filtrar 'mis recetas'</h2>
        <v-text-field
          label="Codigo de receta"
          prepend-icon="mdi-barcode"
          v-model="recipe"
          clearable
          @keyup.enter="onFilter()"
        />
        <v-menu
          ref="menu2"
          v-model="datePickerMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dates"
              label="Fecha de prescripción"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              clearable
            />
          </template>
          <v-date-picker locale="es-es" v-model="dates" range />
        </v-menu>
        <v-spacer></v-spacer>
        <v-row justify="end">
          <v-btn rounded text @click="$emit('clean')">
            Limpiar filtros
          </v-btn>
          <v-btn
            color="teal"
            rounded
            outlined
            @click="onFilter(), (datePickerMenu = false)"
          >
            Filtrar
          </v-btn>
        </v-row>
      </v-sheet>
    </section>
    <v-skeleton-loader class='rounded20' v-else type="card" />
  </div>
</template>
<script>
export default {
  name: "RecipeFilter",

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    recipe: null,
    affiliate: null,
    dates: null,
    menu: false,
    datePickerMenu: false,
  }),

  methods: {
    onFilter() {
      this.$emit("filter", {
        recipe: this.recipe,
        affiliate: this.affiliate,
        dates: this.setDates(),
      });
    },

    setDates() {
      if (this.dates == null) return null;
      return {
        fromDate: this.dates[0],
        toDate: this.dates[1],
      };
    },
  },
};
</script>
