<template>
  <div>
    <template v-if="meds.length">
      <h4 class="overline">Seleccione los renglones a devolver</h4>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="propety in propeties"
                :key="propety.id"
                v-text="propety.text"
                class="text-center"
              />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(med, index) in meds" :key="index" class="text-center">
              <td>
                <v-checkbox
                  :label="med.return ? 'Devolver' : 'No devolver'"
                  v-model="med.return"
                />
              </td>
              <td>
                {{ med.rp }}
              </td>
              <td>{{ med.med }} - {{ med.presentation }}</td>
              <td>
                {{ med.quantity }}
              </td>
              <td>
                <v-text-field
                  v-model="med.reason"
                  hide-details
                  filled
                  rounded
                  :label="!med.return ? 'Seleccione para devolver' : 'Motivo'"
                  :disabled="!med.return"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    <template v-else>
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-alert color="red ligthen-2" border="left" dark type="error">
              La receta no cuenta con renglones
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
export default {
  name: "MedsReturnTable",
  props: {
    meds: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    propeties: [
      {
        key: 1,
        text: "X",
      },
      {
        key: 2,
        text: "RP",
      },
      {
        key: 3,
        text: "Descripcion",
      },
      {
        key: 3,
        text: "Cantidad",
      },
      {
        key: 4,
        text: "Motivo",
      },
    ],
  }),
};
</script>
