<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      v-on:click:outside="$emit('closedialog')"
      max-width='800px'
    >
      <v-card
        class="pa-5 rounded20"
        v-if="!recipeStatusLoading && recipeStatus"
      >
        <v-card-title class="justify-center">
          Estado de la receta {{ recipeId }}
        </v-card-title>
        <v-card-text class="card-cont" v-if="recipeStatus">
          <v-row>
            <v-col>
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(status, index) in computedRecipeStatus"
                  :key="index"
                  :color="status.color"
                >
                  <v-row>
                    <v-col>
                      <strong>
                        {{ status.name }}
                      </strong>
                      <div class="caption">
                        {{ status.caption }}
                      </div>
                      <v-timeline
                        v-if="status.items"
                        dense
                        style="padding: 0px"
                      >
                        <v-timeline-item
                          v-for="(item, index) in status.items"
                          :key="index"
                          small
                          :color="item.color || 'red'"
                        >
                         <div v-if="item.orderedItem !== item.deliveredItem && item.deliveredItem !== '-'">
                          <span class="text-caption d-block">
                            <v-icon size="12px" color='red'>mdi-close-box-multiple</v-icon> <s>{{ item.orderedItem }}</s>
                          </span>
                          <strong>
                            {{ item.deliveredItem }}
                          </strong>
                         </div>
                         <div v-else>
                          <strong>
                            {{ item.orderedItem }}
                          </strong>
                         </div>
                          <div class="caption">
                            <v-btn
                              v-if="item.availableToReception"
                              color="teal"
                              class="mx-2"
                              x-small
                              dark
                              :loading="recipeStatusLoading"
                              @click="setReception(item.id)"
                            >
                              Marcar recepcion
                            </v-btn>
                            <v-btn
                              v-if="item.availableToDispensation"
                              color="teal"
                              class="mx-2"
                              x-small
                              dark
                              :loading="recipeStatusLoading"
                              @click="setDispensation(item.id)"
                            >
                              Marcar dispensa
                            </v-btn>
                            <span v-else>
                              {{ item.status }}
                            </span>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            rounded
            outlined
            v-text="'Reclamo'"
            color="red darken-2"
            :disabled="recipeStatus.status == 'Dispensado'"
            @click="claimDialog = true"
          />

          <v-btn
            rounded
            outlined
            v-text="'Devolución'"
            color="amber darken-2"
            :loading="returnLoading"
            @click="returnDialog = true"
          />
          <v-btn text @click="$emit('closedialog')">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else color="teal" class="py-2" dark>
        <v-card-text>
          Cargando estado de receta...
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ClaimDialog
      v-if="recipeId"
      :showDialog="claimDialog"
      :recipeId="recipeId"
      :key="Date.now().toString()"
      @closedialog="claimDialog = false"
    />
    <ReturnDialog
      v-if="recipeId"
      v-show="returnDialog"
      :showDialog="returnDialog"
      :recipeId="recipeId"
      @closedialog="returnDialog = false"
      @markreturn="markReturn($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { recipeStatus } from "../../../services/recipeUtils";
import ClaimDialog from "../../claims/ClaimsDialogComponent.vue";
import ReturnDialog from "../return/ReturnDialogComponent.vue";

export default {
  name: "RecipeStatusDialog",
  components: {
    ClaimDialog,
    ReturnDialog,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    recipeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    claimDialog: false,
    returnDialog: false,
  }),

  mounted() {
    this.getRecipeStatus(this.recipeId);
  },

  computed: {
    ...mapGetters("recipes", [
      "recipeStatus",
      "recipeStatusLoading",
      "returnLoading",
    ]),
    computedRecipeStatus: function () {
      return [...recipeStatus(this.recipeStatus)];
    },
  },

  methods: {
    ...mapActions("recipes", [
      "getRecipeStatus",
      "setReception",
      "setDispensation",
      "markReturn",
    ]),
  },
};
</script>

<style scoped>
.card-cont {
  max-height: 70vh;
  overflow-y: auto;
  display: block;
}
</style>
