<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      width="30%"
      v-on:click:outside="$emit('closedialog')"
    >
      <v-card v-if="!loading" class="rounded20">
        <v-card v-if="recipeClaims" class="my-1 mx-3" flat>
          <v-card-text class="text-center">
            <v-chip color="teal" outlined dark>
              Reclamos
            </v-chip>
          </v-card-text>
          <v-card-text v-for="(claim, i) in recipeClaims" :key="claim.id">
            <v-divider />
            <v-row v-if="claim.type == 'request'">
              <v-col cols="8">
                <v-card
                  shaped
                  color="cyan lighten-5 rounded-r-xl"
                  elevation="1"
                >
                  <v-card-title class="py-1">
                    <span class="overline">Mensaje</span>
                  </v-card-title>
                  <v-card-text
                    class="text--disabled"
                    v-if="claim.message == ''"
                  >
                    Vacío
                  </v-card-text>
                  <v-card-text v-else>
                    {{ claim.message }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col offset="4" cols="8">
                <v-card
                  shaped
                  color="teal"
                  dark
                  class="rounded-l-xl"
                  elevation="1"
                  v-if="claim.message"
                >
                  <v-card-title class="py-1">
                    <span class="overline">Respuesta</span>
                  </v-card-title>
                  <v-card-text>
                    {{ claim.message }}
                  </v-card-text>
                </v-card>
                <span v-else> Esperando respuesta </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider />
        <v-card class="my-1 mx-3" flat>
          <v-card-title>
            <v-subheader>Crear un reclamo</v-subheader>
          </v-card-title>
          <v-card-text>
            <v-textarea
              :rules="[(v) => !!v || 'Ingrese un mensaje para su reclamo']"
              ref="message"
              required
              prepend-icon="mdi-message"
              append-outer-icon="mdi-send"
              label="Mensaje"
              auto-grow
              filled
              rounded
              v-model="message"
              @click:append-outer="createRecipeClaim()"
            />
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$emit('closedialog')">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else color="red" class="py-2" dark>
        <v-card-text>
          Cargando reclamos...
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="5000"
      rounded
      bottom
      v-model="error.status"
      :color="error.color"
    >
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon rounded v-bind="attrs" @click="error.status = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { format } from 'date-fns'

export default {
  name: "ClaimDialog",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    recipeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    message: "",
    error: {
      status: false,
      message: "",
      color: "",
    },
  }),

  mounted() {
    this.getRecipeClaims(this.recipeId);
  },

  computed: {
    ...mapGetters("claims", ["recipeClaims", "loading"]),
  },

  methods: {
    ...mapActions("claims", ["getRecipeClaims", "setRecipeClaim"]),
    async createRecipeClaim() {

      const valid = this.$refs.message.validate();

      if (valid) {
        const response = await this.setRecipeClaim({
          recipeId: this.recipeId,
          message: this.message,
        }).then((x) => {
          return x;
        });

        if (response.data.message) {
          this.error = {
            status: true,
            message: response.data.message,
            color: "amber darken-3",
          };
        } else if (response.data) {
          this.error = {
            status: true,
            message: "Reclamo enviado con éxito",
            color: "teal",
          };
        } else {
          this.error = {
            status: true,
            message: "Error interno",
            color: "red darken-3",
          };
        }
      }
    },
  },
};
</script>
