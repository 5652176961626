<template>
  <v-snackbar timeout="-1" position="right" outlined multi-line v-model="modal">
    <v-sheet color="white" class="pa-2 text-h6 text-center rounded20">
      <v-icon
        color="blue"
        size="50"
        class="py-6"
        v-text="'mdi-information-outline'"
      />
      <br />
      No olvide recepcionar las recetas en estado 'Enviado'
      <br />
      <v-btn color="teal" rounded outlined @click="$emit('close')">Ok!</v-btn>
    </v-sheet>
  </v-snackbar>
</template>

<script>
export default {
  name: "RecipesInfo",
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
  onMounted() {
    setTimeout(() => {
      this.$emit("close");
    }, 5000);
  },
};
</script>
