<template>
  <div>
    <v-row>
      <v-col sm="12" md="12" xl="3">
        <RecipeBrowser @find-recipe="toDetailsDialog($event.id)" />
        <RecipeFilter
          @filter="getRecipes($event)"
          @clean="getRecipes()"
          :loading="loading"
        />
      </v-col>
      <v-col sm="12" md="12" xl="9">
        <v-sheet color="white" class="pa-10 rounded20">
          <RecipesTable
            :items="recipes"
            :total="recipesCount"
            :loading="loading"
            :properties="tableProperties"
            :buttons="buttomOptions"
            cardTitle="Mis recetas de los últimos (90) días"
            :currentPage="currentPage"
            @detail="toDetailsDialog($event.id)"
            @state="toStateDialog($event.id)"
            @pagination="pageChange($event)"
          >
          </RecipesTable>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row justify="center">
      <RecipeDetail />
      <RecipeStatusDialog
        :showDialog="recipeStatusDialog"
        :recipeId="recipeStateDialogId"
        :key="recipeStateDialogId"
        @closedialog="recipeStatusDialog = false"
      />
    </v-row>
    <RecipesInfo :modal="modalInfo" @close="modalInfo = false" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../../services/eventBus";
import RecipesTable from "../../components/recipes/RecipesTableComponent.vue";
import RecipeDetail from "../../components/recipes/details/RecipeDetailComponent.vue";
import RecipeBrowser from "../../components/recipes/RecipeBrowserComponent.vue";
import RecipeFilter from "../../components/recipes/RecipeFilterComponent.vue";
import RecipeStatusDialog from "../../components/recipes/status/RecipeStatusDialogComponent.vue";
import RecipesInfo from "../../components/recipes/RecipesInfoComponent.vue";

export default {
  components: {
    RecipesTable,
    RecipeBrowser,
    RecipeDetail,
    RecipeFilter,
    RecipeStatusDialog,
    RecipesInfo,
  },

  data: () => ({
    modalInfo: true,
    currentItem: "",
    currentPage: 1,
    recipeStatusDialog: false,
    recipeStateDialogId: "",
    buttomOptions: [
      {
        emit: "state",
        name: "Estado",
      },
      {
        emit: "detail",
        name: "Detalle",
      },
    ],
    tableProperties: [
      {
        key: "id",
        title: "Receta",
      },
      {
        key: "affiliate",
        title: "Afiliado",
      },
      {
        key: "prescDate",
        title: "Prescripción",
      },
      {
        key: "status",
        title: "Estado",
      },
    ],
  }),

  mounted() {
    this.getRecipes();
  },

  computed: {
    ...mapGetters("recipes", ["recipes", "recipesCount", "loading"]),
  },

  methods: {
    ...mapActions("recipes", ["getRecipes"]),

    toStateDialog(recipeId) {
      this.recipeStateDialogId = recipeId;
      this.recipeStatusDialog = true;
    },

    toDetailsDialog(recipeId) {
      eventBus.$emit("recipeDetail", recipeId);
    },
    pageChange(page) {
      this.currentPage = page;
      this.getRecipes({ page: this.currentPage });
    },
  },
};
</script>
