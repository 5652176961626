<template>
  <section>
    <v-text-field
      label="Buscar recetas"
      v-model="recipeCode"
      ref="search"
      filled
      rounded
      flat
      background-color="white"
      clearable
      required
      lazy-validation
      append-icon="mdi-magnify"
      @click:append="validate()"
      @keyup.enter="validate()"
      :rules="[(v) => !!v || 'Ingrese un código válido']"
    >
      <template v-slot:prepend-inner>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
          </template>
          <h2 class="overline">
            recetas asignadas a la farmacia o sin asignar
          </h2>
        </v-tooltip>
      </template>
    </v-text-field>
  </section>
</template>
<script>
export default {
  name: "RecipeBrowser",
  data: () => ({
    recipeCode: "",
  }),

  methods: {
    validate() {
      const valid = this.$refs.search.validate();
      if (valid) {
        this.$emit("find-recipe", { id: this.recipeCode });
      }
    },
  },
};
</script>
