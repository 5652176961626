export function recipeStatus(statusArr) {
  return [
    /* Prescripcion */
    {
      name: "Prescripción",
      caption: statusArr.prescDate
        ? statusArr.prescDate
        : "Esperando prescripción",
      color: statusArr.prescDate
        ? "teal lighten-1"
        : "amber lighten-3",
    },
    /* Pedido */
    {
      name: "Asignación a farmacia",
      caption: statusArr.orderDate
        ? statusArr.orderDate
        : "Esperando pedido",
      color: statusArr.orderDate
        ? "teal lighten-1"
        : "amber lighten-3",
    },
    /* Entrega */
    {
      ...deliveryPropeties(statusArr.status),
      items: statusArr.meds.map((x) => {
        return {
          date: x.deliveryDate,
          orderedItem: x.orderedItem,
          deliveredItem: x.deliveredItem,
          status: !x.nulled
            ? x.deliveryDate || "En preparación"
            : "Anulado",
          color: !x.nulled
            ? x.deliveryDate
              ? "teal lighten-1"
              : "light-blue lighten-1"
            : "red darken-4",
        };
      }),
    },
    /* Recepcion */
    {
      ...receptionPropeties(statusArr.status),
      items: statusArr.meds.map((x) => {
        return {
          date: x.receptionDate,
          orderedItem: x.orderedItem,
          deliveredItem: x.deliveredItem,
          id: x.id,
          ...receptionRowStatus(x),
        };
      }),
    },
    /* Dispensa */
    {
      ...dispensationPropeties(statusArr.status),
      items: statusArr.meds.map((x) => {
        return {
          date: x.dispensationDate,
          orderedItem: x.orderedItem,
          deliveredItem: x.deliveredItem,
          id: x.id,
          ...dispensationRowStatus(x),
        };
      }),
    },
  ]
}

function deliveryPropeties(status) {
  const [currentStatus, send, partialSend] = indexes([status, 'Enviado', 'Envío Parcial'])
  switch (true) {
    case currentStatus >= send:
      return {
        name: "Entrega a farmacia",
        color: 'teal lighten-3',
        caption: 'Enviado'
      };
    case currentStatus == partialSend:
      return {
        name: "Entrega a farmacia",
        color: 'amber lighten-3',
        caption: 'Envio parcial'
      }
    case currentStatus < partialSend:
      return {
        name: "Entrega a farmacia",
        color: 'light-blue lighten-1',
        caption: 'Esperando envío'
      }
  }
}

function receptionPropeties(status) {
  const [currentStatus, reception, partialReception] = indexes([status, 'Recepcionado', 'Recepcionado Parcial'])

  switch (true) {
    case currentStatus >= reception:
      return {
        name: "Recepción",
        color: 'teal lighten-3',
        caption: 'Recepcionado'
      };
    case currentStatus == partialReception:
      return {
        name: "Recepción en farmacia",
        color: 'amber lighten-3',
        caption: 'Recepcion parcial'
      };
    case currentStatus < partialReception:
      return {
        name: "Recepción en farmacia",
        color: 'light-blue lighten-3',
        caption: 'A recepcionar'
      }
  }
}

function receptionRowStatus(row) {

  console.log(row);
  switch (true) {
    case row.nulled == 1:
      return {
        status: 'Anulado',
        color: 'red darken-4'
      }
    case row.deliveryDate == null:
      return {
        status: 'Esperando entrega',
        color: 'amber lighten-3',
      }
    case row.deliveryDate != '-' && row.receptionDate == '-':
      return {
        color: 'light-blue lighten-1',
        availableToReception: true,
      }
    case row.receptionDate != null && row.receptionDate != '-':
      return {
        status: row.receptionDate,
        color: 'teal ligthen-3',
      }
  }
}

function dispensationPropeties(status) {
  const [currentStatus, dispensation] = indexes([status, 'Dispensado'])


  switch (true) {
    case currentStatus >= dispensation:
      return {
        name: "Dispensa al afiliado",
        color: 'teal lighten-3',
        caption: 'Dispensado'
      };
    case currentStatus < dispensation:
      const [send, reception] = indexes(['Enviado', 'Recepcionado'])
      if (currentStatus == reception) return {
        name: "Dispensa al afiliado",
        color: 'light-blue lighten-1',
        caption: 'Esperando recepcion'
      }
      else return {
        name: "Dispensa a afiliado",
        color: 'amber lighten-3',
        caption: 'Esperando envío'
      }
  }
}

function dispensationRowStatus(row) {

  switch (true) {
    case row.nulled == 1:
      return {
        status: 'Anulado',
        color: 'red darken-4'
      }
    case row.receptionDate == '-':
      return {
        status: 'Esperando recepción',
        color: 'amber lighten-3',
      }
    case row.receptionDate != '-' && row.dispensationDate == '-':
      return {
        color: 'light-blue lighten-1',
        availableToDispensation: true,
      }
    case row.dispensationDate != '-':
      return {
        status: row.dispensationDate,
        color: 'teal ligthen-3',
      }
  }
}

function indexes(items) {
  return items.map(x => {
    return x = statusConstants.indexOf(x)
  })
}

const statusConstants = [
  "Sin Asignar",
  "Devuelto",
  "Anulado",
  "En Preparación",
  "Envío Parcial",
  "Enviado",
  "Recepcionado Parcial",
  "Recepcionado",
  "Dispensado Parcial",
  "Dispensado",
]
